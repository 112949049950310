import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import BlogRoll from '../components/BlogRoll'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import LinkButton from '../components/LinkButton'
import PageTitle from '../components/PageTitle'
import ProjectMap from '../components/ProjectMap'
import TitleImage from '../components/TitleImage'

export const IndexPageTemplate = ({
  image,
  title,
  mainpitch,
  intro,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
  <div>
    {!!image ? (
      <TitleImage title={title} image={image.childImageSharp.fluid} styles={{"backgroundPosition": "bottom"}}/>
    ) : null }
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <PageTitle title={mainpitch.title}/>
                  <div className="tile mb-15">
                    <span className="keep-whitespace">{mainpitch.description}</span>
                  </div>
                  <div className="columns">
                    <div className="column has-text-centered">
                      <LinkButton link="/about-us"/>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <PageTitle title={intro.heading}/>
                  <div className="box project-map">
                    <ProjectMap />
                  </div>
                  <div className="tile mb-15">
                    <PageContent className={`content`} content={content} />
                  </div>
                  <div className="columns">
                    <div className="column has-text-centered">
                      <LinkButton link="/projects"/>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <PageTitle title="Latest News"/>
                  <a 
                    href={`/newsletters/20_2023.pdf`} 
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button is-size-4-desktop is-size-6-mobile is-success 
                      has-text-weight-bold mb-2 mt-15 is-fullwidth latest-newsletter-button">
                      Read our latest newsletter
                    </button>
                  </a>
                  <BlogRoll displayTags={["news", "upcoming-events"]} maxNumberPosts={6}/>
                  <div className="column has-text-centered">
                    <LinkButton link="/news"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: pageData } = data

  return (
    <Layout>
      <IndexPageTemplate
        image={pageData.frontmatter.image}
        title={pageData.frontmatter.title}
        mainpitch={pageData.frontmatter.mainpitch}
        intro={pageData.frontmatter.intro}
        content={pageData.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
        intro {
          heading
          description
        }
      }
    }
  }
`
